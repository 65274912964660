import { Component, Prop, Mixins } from 'vue-property-decorator';
import { Tarif, BonusSecteur } from '@/models';
import { DataTableHeader } from '@/shared/models';
import template from './GestionTarifsTab.vue';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { DateHelper } from '@/shared/helpers';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';

@Component({
    ...template,
    name: 'GestionTarifsTab',
    components: { CeeDatePicker },
})
export default class GestionTarifsTab extends Mixins(GrilleMixin) {

    /**
     * Tarifs en cours pour le Profil concerné par cet onglet.
     */
    @Prop({ default: () => ({} as Tarif) })
    public readonly tarif!: Tarif;

    /**
     * Bonus en cours pour les secteurs d'opérations.
     */
    @Prop({ default: () => (new Array<BonusSecteur>()) })
    public readonly bonus!: BonusSecteur[];

    @Prop()
    public readonly currentTab!: string;

    /**
     * Permet la gestion de l'affichage du menu pour le datepicker de la date d'application d'un tarif.
     */
    private menuDebut: boolean = false;

    private isEditMode: boolean = false;

    /**
     * Permet la gestion de l'affichage du menu pour le datepicker de la date de fin d'application d'un tarif.
     */
    private menuFin: boolean = false;

    /**
     * Définition des noms de colonnes du tableau de gestion des tarifs.
     */
    private columns: DataTableHeader[] = [
        { text: 'Secteur', align: 'left' },
        { text: 'Bonus classique', align: 'left' },
        { text: 'Valorisation classique', align: 'left' },
        { text: 'Bonus précarité', align: 'left' },
        { text: 'Valorisation précarité', align: 'left' },
        { text: 'Date d\'application', align: 'left' },
        { text: 'Date de fin d\'application', align: 'left' },
    ];

    /**
     * Permet d'obtenir le résultat de la somme du bonus classique d'un secteur et du tarif classique du Profil de l'onglet.
     * @param secteur Secteur d'opérations (une ligne du data-table).
     */
    private getValorisationClassique(secteur: string): number {
        if (!!secteur) {
            return +(this.bonus.filter((b) => b.secteurId.toUpperCase() === secteur.toUpperCase())[0].bonusClassique || 0) + +(this.tarif.valorisationClassique || 0);
        }
        return 0;
    }

    /**
     * Permet d'obtenir le résultat de la somme du bonus précarité d'un secteur et du tarif précarité du Profil de l'onglet.
     * @param secteur Secteur d'opérations (une ligne du data-table).
     */
    private getValorisationPrecarite(secteur: string): number {
        if (!!secteur) {
            return +(this.bonus.filter((b) => b.secteurId.toUpperCase() === secteur.toUpperCase())[0].bonusPrecarite || 0) + +(this.tarif.valorisationPrecarite || 0);
        }
        return 0;
    }

    /**
     * Permet d'obtenir les dates au format FR (DD/MM/YYYY).
     * @param date
     */
    private getDateFormated(date: string) {
        return DateHelper.format(date);
    }

    /**
     * Obtient la date du jour.
     */
    private get aujourdhui(): string {
        return DateHelper.todayIso();
    }

    /**
     * Renvoie la date saisie, avec l'heure si c'est la date
     * du jour.
     * @param date
     */
    private getDateTarifs(date: string): string {
        return this.getDateFormated(date) === this.getDateFormated(this.aujourdhui) ? this.aujourdhui : date;
    }

}
