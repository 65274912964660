import { Component, Vue } from 'vue-property-decorator';
import template from './GestionTarifs.Template.vue';
import { ApiService } from '@/services/base/ApiService';
import { Tarif, BonusSecteur, TarifsEtBonus } from '@/models';
import { GestionTarifsEnum } from '@/shared/enums';
import { ConvertHelper } from '@/shared/helpers';
import GestionTarifsTab from '@/components/GestionTarifs/GestionTarifsTab.ts';
import { Profils } from '@/shared/enums';

/**
 * Classe TS associée à la vue de gestion des tarifs.
 */
@Component({
    ...template,
    name: 'GestionTarifs',
    components: { GestionTarifsTab },
})
export default class GestionTarifs extends Vue {

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    /**
     * Liste des tarifs en cours pour chacun des Profils utilisateurs.
     */
    public tarifsProfilsEnCours: Tarif[] | null = null;

    /**
     * Liste des bonus en cours pour chacun des secteurs d'opérations.
     */
    public bonusSecteursEnCours: BonusSecteur[] = new Array<BonusSecteur>();

    /**
     * Objet regroupant l'ensemble des tarifs et des Profils.
     */
    public tarifEtBonus: TarifsEtBonus = new TarifsEtBonus(this.tarifsProfilsEnCours, this.bonusSecteursEnCours);

    /**
     * Tarifs en cours pour les comptes Entreprise.
     */
    public get tarifsEntreprise(): Tarif {
        return this.getTarifsByProfil(GestionTarifsEnum.Entreprise);
    }

    /**
     * Tarifs en cours pour les comptes Installateur.
     */
    public get tarifsInstallateur(): Tarif {
        return this.getTarifsByProfil(GestionTarifsEnum.Installateur);
    }

    /**
     * Tarifs en cours pour les comptes SyndicOuSDC.
     */
    public get tarifsSyndicOuSDC(): Tarif {
        return this.getTarifsByProfil(GestionTarifsEnum.SyndicSDC);
    }

    /**
     * Récupération du tarif en cours pour le Profil passé en paramètre de la méthode.
     * Conversion des valorisations de kWhc vers MWhc.
     * @param profil
     */
    public getTarifsByProfil(profil: string): Tarif {
        if (this.tarifsProfilsEnCours.length > 0) {
            const tarifs = this.tarifsProfilsEnCours.filter((t) => t.profilId.toUpperCase() === profil.toUpperCase());
            return tarifs[0];
        }
        throw new Error('Aucun tarifs n\'a été récupéré.');
    }

    /**
     * Récupération du tarif en cours pour le secteur passé en paramètre de la méthode.
     * Conversion des valorisations de kWhc vers MWhc.
     * @param secteur
     */
    public getBonusBySecteur(secteur: string): BonusSecteur {
        if (!!this.bonusSecteursEnCours) {
            return this.bonusSecteursEnCours.filter((b) => b.secteurId.toUpperCase() === secteur.toUpperCase())[0];
        }
        throw new Error('Aucun bonus n\'a été récupéré.');
    }

    /**
     * Fonction s'exécutant juste après l'initialisation de la page.
     * Dans ce cas : Appels axios pour récupérer les données.
     */
    public mounted() {
        this.obtenirTarifsProfilsEnCours().then((items) => {
            this.tarifsProfilsEnCours = items;
            items.forEach((tarif) => {
                tarif.valorisationClassique = ConvertHelper.toEurosMWhc(tarif.valorisationClassique);
                tarif.valorisationPrecarite = ConvertHelper.toEurosMWhc(tarif.valorisationPrecarite);
            });
        });
        this.obtenirBonusSecteursEnCours().then((items) => { this.bonusSecteursEnCours = items; });
    }

    /**
     * Appel Axios pour récupérer les tarifs en vigueurs pour les Profils "Entreprise", "Installateur", "SyndicOuSDC".
     */
    public obtenirTarifsProfilsEnCours(): Promise<Tarif[]> {
        const fetchAllTarifsProfils = new ApiService<Tarif>('tarif/obtenirTarifsProfilsEnCours');
        return new Promise<Tarif[]>((resolve, reject) => {
            return fetchAllTarifsProfils.getAll().then((response) => {
                resolve(response.data.data);
            }).catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Appel axios pour récupérer les tarifs et les bonus par secteurs.
     */
    public obtenirBonusSecteursEnCours(): Promise<BonusSecteur[]> {
        const fetchAllTarifsEtBonus = new ApiService<BonusSecteur[]>('tarif/obtenirBonusSecteursEnCours');
        return new Promise<BonusSecteur[]>((resolve, reject) => {
            return fetchAllTarifsEtBonus.getAll().then((response) => {
                resolve(response.data.data as any);
            }).catch((error: { response: any }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Mise à jour des bonus par secteurs.
     */
    private updateTarifsEtBonus(tarifEtBonus: TarifsEtBonus) {
        const updateBonus = new ApiService<TarifsEtBonus>('tarif/updateTarifsEtBonus');
        return new Promise<TarifsEtBonus>((resolve, reject) => {
            return updateBonus.post(tarifEtBonus).then((response) => {
                resolve(response.data.data);
            }).catch((error: { response: any; }) => {
                reject(error.response);
            });
        });
    }

    /**
     * Appel du submit pour la mise à jour des entités TarifsProfil et BonusSecteur.
     */
    private submit() {
        const tarifEtBonus: TarifsEtBonus = new TarifsEtBonus(this.tarifsProfilsEnCours, this.bonusSecteursEnCours);
        this.updateTarifsEtBonus(tarifEtBonus);
    }
}
